/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { Spinner } from "react-bootstrap"
import { useSelector } from "react-redux"
import { navigate } from "gatsby-link"
import axios from "axios"

const Description = ({ role, form }) => {
  const language = useSelector(state => state.global.language)

  const [isLoading, setLoader] = useState(false)
  const [fileUpload, setfileUpload] = useState({})
  const [formValues, setFormvalue] = useState({
    jobTitle: role.positionTitle[language],
  })
  const emailRegex =
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  const phoneRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()

  const handleInputChange = ({ name, value }) => {
    setFormvalue({ ...formValues, [name]: value })
  }

  // //Resetting form when language changes
  //   useEffect(() => {
  //     reset()
  //   }, [language])

  const handleFileChange = e => {
    setfileUpload({ file: e.target.files[0] })
  }

  const formSubmit = e => {
    setLoader(true)
    const formData = new FormData()
    const updatedFormValues = {
      ...formValues,
      page: "Careers",
      name: `${formValues.name}`,
      email: `${formValues.email}`,
      phone: `${formValues.phone}`,
      jobTitle: `${formValues.jobTitle}`,
      experience: `${formValues.experience}`,
      linkedinprofile: `${formValues.linkedinprofile}`,
      website: `${formValues.website}`,
      message: `${formValues.message}`,
      ...JSON.parse(localStorage.getItem("utmTags")),
    }

    // console.log({ updatedFormValues });
    Object.keys(updatedFormValues).forEach(key => {
      formData.append(key, updatedFormValues[key])
    })

    const submitContactForm = data =>
    new Promise((resolve, reject) => {
      // const token =
      //   "P/:rzKO@@7FP=VBOuYnAh{uC!<MHJ$]sct(F<(m^?<`32+>^Z[zBtg]klPw?WaI%"
      const URL =
        "https://sweeden-backend.webdura.info/wp-json/contact-form-7/v1/contact-forms/2744/feedback"

      axios(URL, {
        method: "POST",
        data: data,
        headers: {
          // Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(() => {
          resolve()
        })
        .catch(err => {
          reject(err)
          console.log("Error", err)
        })
    })

  const googleFormSubmission = data =>
    new Promise(async (resolve, reject) => {
      const URL =
        "https://script.google.com/macros/s/AKfycbyAphKwhP3HVxKfW0j9d62eMc5eOBG6sViC4KS4bGzDxqknnrDXFwmRwmUplUPLdG_O/exec"
      try {
        await axios(URL, {
          method: "POST",
          data: data,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        setLoader(false)
        navigate("/thankyou")
      } catch (error) {
        reject(error)
      }
    })



    const form2 = googleFormSubmission(formData)

    formData.append("fullName", formValues.name)
    formData.append("fileupload", fileUpload.file)

    const form1 = submitContactForm(formData)


    Promise.all([form1, form2])
      .then(() => {
        console.log("All Form Submitted")
        setLoader(false)
        navigate("/thankyou")
      })
      .catch(err => {
        console.log(err)
      })


  }

  const formValidation = {
    name: {
      required: "Name is Required",
    },
    email: {
      required: "Email is Required",
      pattern: {
        value: emailRegex,
        message:
          language === "sv" ? "Ogiltig e-postadress" : "Invalid email address",
      },
    },
    phone: {
      required: "Phone is Required",
      pattern: {
        value: phoneRegex,
        message:
          language === "sv" ? "Ogiltigt telefonnummer" : "Invalid phone number",
      },
    },
    jobTitle: {
      required: "Job Title is Required",
    },
    experience: {
      required: "Experience is Required",
    },
    message: {
      required: "Message is Required",
    },
  }
  return (
    <section className="career_detail">
      <div className="container p-0">
        <div className="c_detail">
          <div className="c_detail_block">
            <div className="c_detailtxt">
              <h3>Department: {role.department[language]}</h3>
              <h2 className="split-text">{role.positionTitle[language]}</h2>
              <h4>
                <span className="au_icon icon_location" />{" "}
                {role.location[language]}
              </h4>
              <p>{role.description[language]}</p>
            </div>
            <div className="c_detailtxt">
              <h5>{role.responsibilities.title[language]}</h5>
              <ul>
                {role.responsibilities.list.map(res => (
                  <li>{res.label[language]}</li>
                ))}
              </ul>
              <h5>{role.skills.title[language]}</h5>
              <ul>
                {role.skills.list.map(res => (
                  <li>{res.label[language]}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="c_detail_blockk">
            <h2>{form.title[language]}</h2>
            <p>{form.text[language]}</p>
            <form onSubmit={handleSubmit(formSubmit)}>
              {form.fields.map(field =>
                field.type === "textarea" ? (
                  <div className="form-group mb-0">
                    <label>{field.label[language]}</label>
                    <textarea
                      name={field.name}
                      rows="4"
                      cols="4"
                      {...register(`${field.name}`, {
                        ...formValidation[field.name],
                        required: field.errorMessage[language],
                      })}
                      onChange={({ target }) => handleInputChange(target)}
                    />
                    <span
                      style={{
                        color: "red",
                        fontSize: "14px",
                        textAlign: "left",
                      }}
                    >
                      {errors[field.name] && errors[field.name].message}
                    </span>
                  </div>
                ) : (
                  <div className="form-group">
                    <label>{field.label[language]}</label>
                    <input
                      disabled={field.label[language] === "Job Title *"}
                      type={field.type}
                      name={field.name}
                      value={formValues[field.name]}
                      {...register(`${field.name}`, {
                        ...formValidation[field.name],
                        required: field.errorMessage[language],
                      })}
                      onChange={({ target }) => handleInputChange(target)}
                    />
                    <span
                      style={{
                        color: "red",
                        fontSize: "14px",
                        textAlign: "left",
                      }}
                    >
                      {errors[field.name] && errors[field.name].message}
                    </span>
                  </div>
                )
              )}
              <div className="form-group mb-0">
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept=".doc,.docx,.pdf"
                  onChange={e => handleFileChange(e)}
                />
                <div className="form_file">
                  <div>
                    <label htmlFor="file" className="btn-2">
                      <span className="au_icon icon_attach" />
                    </label>
                  </div>
                  <div className="file_frmt">
                    <span>{form.attachFile.title[language]}</span>
                    <span className="d-block filetype">
                      {form.attachFile.formats[language]}
                    </span>
                  </div>
                  <p>{formValues.file}</p>
                </div>
                <p>
                  {Object.keys(fileUpload).length === 0
                    ? null
                    : fileUpload.file.name}
                </p>
                <button type="submit" className="webbtn m-0">
                  {isLoading ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    <>
                      {form.buttonText[language]}
                      <i className="arrow" />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Description
